@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Spartan', sans-serif;
  box-sizing: border-box;
  }

.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutPic{
  display: flex;
  align-self: center;
  margin-top: 2rem;
}

.subpageWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  min-height: 0;
  position: absolute;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px;
  margin-bottom: 1rem;
}

.portfolioWrapper{
  padding: 3rem;
  min-height: 0;
}

.gifs{
  display:flex;
  justify-content: space-between;
  height: 260px;
}

.projects{
  text-align: center;
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.projects > a {
  background: #34524f;
  padding: 1rem;
  display: grid;
  text-decoration: none;
}

.projects > a::before{
  content: "";
  padding-bottom: 100%;
  display: block;
  grid-area: 1 / 1 / 2 / 2;
}

.projects > a > img, 
.projects > a > p, 
.projects > a > p:visited,
.projects > a > p:link  {
  width: 100%;
  height: 100%;
  display:flex;
  object-fit: contain;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: center;
  align-items: center;
  color: white;
}

.socialLink, .socialLink:visited {
  font-size: 40px;
  color: black;
}

.socialLink:hover{
  color: #7BABA6;
}

.projectLinks:hover,.otherLinks:hover {
  background-color:#7BABA6;
}

.panel{
  display: flex;
}

.alignLeft{
  text-align: left;
  align-self: center;
}

/* Control the left side */
.left {
  position: relative;
  overflow: hidden;
  left: 0;
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 0;
}

/* Control the right side */
.right {
  right: 0;
  background: #34524f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100vh;
  overflow-x: hidden;
}
.myPic{
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: -1;
  border-radius: 5px;
  align-self: center;
  display: flex;
  height: auto;
}

.bottomNav{
  overflow-x: hidden;
  position: fixed;
  padding: 1rem 2rem;
  top: 0;
  left: 0;
  text-align: left;
  width: 50%;
  background-color: #f4f8f3;
  z-index: 100;
}

.navLink, .navLink:visited{
  text-decoration: none;
  color: #34524f;
}

.navLink:hover{
  text-decoration: underline;
}

.logo{
  max-width: fit-content;
  align-self: center;
  margin-top: 2rem;
}

/* arrow animation */

.scroll-down-arrow {
	background-image: url(https://static.thenounproject.com/png/427197-200.png);
	background-size: contain;
	background-repeat: no-repeat;
  visibility: hidden;
}

.scroll-down-link {
	height: 60px;
	width: 60px;
	margin: 0px 0 0 -30px;
	line-height: 50px;
	position: absolute;
	left: 50%;
	bottom: 0px;
	z-index: 100;
	-webkit-animation: ca3_fade_move_down 3s ease-in-out infinite;
	-moz-animation:    ca3_fade_move_down 3s ease-in-out infinite;
	animation:         ca3_fade_move_down 3s ease-in-out infinite;
}


/*animated scroll arrow animation*/
@-webkit-keyframes ca3_fade_move_down {
  0%   { -webkit-transform:translate(0,-15px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,15px); opacity: 0; }
}
@-moz-keyframes ca3_fade_move_down {
  0%   { -moz-transform:translate(0,-15px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,15px); opacity: 0; }
}
@keyframes ca3_fade_move_down {
  0%   { transform:translate(0,-15px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,15px); opacity: 0; }
}

/*Media Queries*/

@media screen and (max-width:1780px) {
  .panel{
    height: auto;
    display: flex;
    justify-content: center;
    min-height: 0;
  }
  .left{
    width: 50%;
    min-height: 0;
  }

  .gifs{
    flex-direction: column;
    align-items: center;
  }

  .gifs > img{
    height: 240px;
  }
}
@media screen and (max-width:1000px) {
  .panel{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 0;
  }
  
  .right {
    width:100%;
    height: 100%;
    overflow-x: visible;
  }
  .left{
    width: 100%;
    height: 85vh;
    padding-top: 1rem;
    min-height: 0;
  }

  .gifs{
    flex-direction: column;
    align-items: center;
  }

  .gifs > img{
    height: 225px;
  }

  .myPic{
    width: auto;
    height: 260px;
    overflow: hidden;
  }

  .aboutPic{
    margin-top: 3rem;
  }

  .bottomNav{
    width: 100%;
  }

  .subpageWrapper{
    right: 0;
  }

  .logo{
    max-width: fit-content;
    align-self: center;
    margin-top: 12rem;
  }
  .scroll-down-arrow{
    visibility: visible;
  }
}